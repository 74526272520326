import React, { memo } from 'react';
import Layout from "../components/layout/layout";

const days = [
  {
    "name": "Maandag",
    "frenchName": "Lundi",
    "englishName": "Monday",
    "soup": "Paprikasoep",
    "frenchSoup": "Soupe de poivrons",
    "englishSoup": "Paprika soup",
    "mainCourse": "Pasta met vier kazen",
    "frenchMainCourse": "Pâtes aux quatre fromages",
    "englishMainCourse": "Four cheese pasta",
    "dessert": null
  },
  {
    "name": "Dinsdag",
    "frenchName": "Mardi",
    "englishName": "Tuesday",
    "soup": "Courgettesoep",
    "frenchSoup": "Soupe de courgettes",
    "englishSoup": "Zucchini soup",
    "mainCourse": "Chipolata met preipuree",
    "frenchMainCourse": "Chipolata avec purée au poireaux",
    "englishMainCourse": "Chipolata with leek mashed potatoes",
    "dessert": null
  },
  {
    "name": "Woensdag",
    "frenchName": "Mercredi",
    "englishName": "Wednesday",
    "soup": "Aardappelsoep met spekjes",
    "frenchSoup": "Soupe de pommes de terre et lardons",
    "englishSoup": "Potato soup with bacon",
    "mainCourse": "Kippenbout, champignonsaus, frietjes",
    "frenchMainCourse": "Cuisse de poulet, sauce aux champignons, frites",
    "englishMainCourse": "Chicken leg, mushroom sauce, fries",
    "dessert": null
  },
  {
    "name": "Donderdag",
    "frenchName": "Jeudi",
    "englishName": "Thursday",
    "soup": "Ajuinsoep",
    "frenchSoup": "Soupe à l’oignon",
    "englishSoup": "Onion soup",
    "mainCourse": "Varkensspiering met mosterdsaus, aardappelkroketten",
    "frenchMainCourse": "Spiringue de porc, sauce moutarde, croquettes de pommes de terre",
    "englishMainCourse": "Pork spiring with mustard sauce, potato croquettes",
    "dessert": null
  },
  {
    "name": "Vrijdag",
    "frenchName": "Vendredi",
    "englishName": "Friday",
    "soup": "Pastinaaksoep",
    "frenchSoup": "Soupe de panais",
    "englishSoup": "Parsnip soup",
    "mainCourse": "Doradefilet met kappertjes en botersaus, aardappelpuree",
    "frenchMainCourse": "Filet de dorade, sauce aux câpres et beurre, purée de pommes de terre",
    "englishMainCourse": "Sea bream fillet with caper butter sauce, mashed potatoes",
    "dessert": null
  },
  {
    "name": "Zaterdag",
    "frenchName": "Samedi",
    "englishName": "Saturday",
    "soup": "Groenteroomsoep",
    "frenchSoup": "Crème de légumes",
    "englishSoup": "Vegetable cream soup",
    "mainCourse": "Gehaktballen met Provençaalse saus, rijst",
    "frenchMainCourse": "Boulettes sauce provençale, riz",
    "englishMainCourse": "Meatballs with Provençal sauce, rice",
    "dessert": null
  },
  {
    "name": "Zondag",
    "frenchName": "Dimanche",
    "englishName": "Sunday",
    "soup": "Tomaten-currysoep",
    "frenchSoup": "Soupe de tomates au curry",
    "englishSoup": "Tomato curry soup",
    "mainCourse": "Kipfilet, mix van wintergroenten, dragonsaus, aardappelkroketten",
    "frenchMainCourse": "Filet de poulet, mix de legumes d’hiver, sauce estragon, croquettes pommes de terre",
    "englishMainCourse": "Chicken filet, mix of winter vegetables, tarragon sauce, potato croquettes",
    "dessert": null
  }
];
const alternativeMenu = {
  "dutch": "Kalfsblanquette met frietjes",
  "french": "Blanquette de veau avec frites",
  "english": "Veal blanquette with fries"
};
const Lunch = () => {
  return (
      <>
          <Layout/>
          <main className="main">
              <section className='lunch'/>
              <div className="best_price">
                  <img
                      loading='lazy'
                      src="/images/best_price_2.webp"
                      alt="Lunch"
                  />
              </div>
              <div className="top_title">
                  <h1>Lunch</h1>
              </div>

              <div className='lunch_wrap'>
                  <div className='lunch_box'>
                      <h3>20/01 – 26/01</h3>
                      <h3>11:30 – 15:00</h3>
                              {/* Альтернативное меню */}
                      {/* <div className="alternative_menu">
                          <h3 style={{color:'#ae975f'}}>Alternatief gerecht voor deze week:</h3>
                          <p style={{color:'#ae975f'}}>{alternativeMenu.dutch} - {alternativeMenu.french} - {alternativeMenu.english}</p>
                      </div>       */}
                      {days.map((day, index) => (
                          <div key={index}>
                              <h4>{day.name} – {day.frenchName} – {day.englishName}</h4>
                              <p>{day.soup} - {day.frenchSoup} - {day.englishSoup}</p>
                              <p>{day.mainCourse}</p>
                              <p>{day.frenchMainCourse}</p>
                              <p>{day.englishMainCourse}</p>
                              {day.dessert && (
                                  <p>{day.dessert} - {day.frenchDessert} - {day.englishDessert}</p>
                              )}
                          </div>
                      ))}
                  </div>
              </div>
          </main>
      </>
  );
};


export default memo(Lunch);
